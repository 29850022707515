const menu = {
    loaded: true,
    menuItems: [
      {
        instance: 'default',
        title: '<b>HOME</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
      },
      {
        instance: 'CLINICAL_DATA',
        title: '<b>CLINICAL DATA</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
        subMenu: [
          {
            title: 'CLINICAL PROGRAM',
            subMenu: [
                { title: 'TRAIL PROGRAM' },
                { title: 'ATOPIC DERMATITIS TRAILS' }
            ]
          },
          {
            title: 'EFFICACY',
            subMenu: [
                { title: 'ITCH REDUCTION' },
                { title: 'SKIN CLEARANCE' },
                { title: 'EASI ACROSS BODY REGIONS' },
                { title: 'FLARE DATA' },
            ]
          },
          {
            title: 'SAFETY PROFILE',
            subMenu: [
                { title: 'LONG TERM SAFETY PROFILE' },
                { title: 'IMPORTANT CONSIDERATIONS' },
            ]
          },
          {
            title: 'OTHER ATTRIBUTES'
          },
          {
            title: 'REAL-WORLD DATA',
            subMenu: [
                { title: 'ITCH REDUCTION/SKIN CLEARANCE' },
                { title: 'SATISFACTION/PERSISTENCE' },
            ]
          },
        ]
      },
      {
        instance: 'STUDY_DESIGNS',
        title: '<b>STUDY DESIGNS</b>',
        color: 'stripNavBgSecondary',
        mobileColor: 'stripNavMobileBgPrimary'
      },
      {
        instance: 'VISIBLE_RESULTS',
        title: '<b>VISIBLE RESULTS</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
        subMenu: [
          { title: 'ADULTS' },
          { title: 'ADOLESCENTS' },
          { title: 'CHILDREN' },
          { title: 'STUDY DESIGN' },
        ]
      },
      {
        instance: 'REAL_STORIES',
        title: '<b>REAL STORIES</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
        subMenu: [
          { title: 'STUDY DESIGNS', mobileTitle: 'STUDY DESIGNS AND DATA' },
          { 
            title: 'PHYSICIAN STORIES',
            subMenu: [
              { title: 'DR BHATIA' },
              { title: 'DR SOUNG' },
              { title: 'DR SPIZUOCO' },
            ]
          },
          { 
            title: 'PATIENT/CAREGIVER STORIES',
            subMenu: [
              { title: 'ANNIE' },
              { title: 'RACHEL' },
              { title: 'YETUNDE' },
            ]
          }
        ]
      },
      {
        instance: 'MOA',
        title: '<b>MECHANISM OF ACTION</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
        subMenu: [
            { title: 'ROLE OF TYPE 2 INFLAMMATION' },
            { title: 'INHIBITION OF IL-4 AND IL-13 SIGNALING' },
            { title: 'MOA VIDEO' }
        ]
      },
      {
        instance: 'GETTING_STARTED',
        title: '<b>GETTING STARTED</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
        subMenu: [
          { title: 'PATIENT SUPPORT OVERVIEW' },
          { title: 'PATIENT SUPPORT VIDEOS' },
          { title: 'FORMULARY COVERAGE' },
          { title: 'DOSING' }
        ]
      },
      {
        instance: 'DOWNLOADS',
        title: '<b>DOWNLOADS</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary'
      },
      {
        instance: 'EXTERNAL_LINK',
        linkTitleUrl: 'https://www.dupixenthcp.com/atopicdermatitis/access-support/request-a-field-representative',
        contactUsTitle: 'Lorem Ipsum',
        title: 'REQUEST A REP',
        icon: 'requestRep',
        color: 'stripNavBgActive',
        mobileColor: 'stripNavBgBorder',
        mobileTextColor: 'stripNavBgActive',
        mobileBorderColor: 'stripNavBgBorder'
      },
    ],
  };

export default menu;