import { contentUrl } from '../api'

export const DOWNLOADS = {
  name: 'DOWNLOADS',
  title: 'DOWNLOADS',
  mobileType: "mobile",
  content: [
    {
      content: {
        contentTemplate: 'DOWNLOADS',
        title: 'Downloads',
        description: 'Access educational PDF resources about DUPIXENT',
        downloadsItem: [
          { name: 'Dupixent Brochure', image: `${contentUrl}/downloads/v5/dupixent-brochure.png`, asset: `${contentUrl}/downloads/v5/Why_Choose_DUPIXENT_Brochure.pdf` },
          { name: 'Safety Brochure', image: `${contentUrl}/downloads/v5/safety-brochure.png`, asset: `${contentUrl}/downloads/v5/Safety-Profile-Brochure.pdf` },
          { name: 'Dosing Card', image: `${contentUrl}/downloads/v5/dosing-card.png`, asset: `${contentUrl}/downloads/v5/Dosing-Card.pdf` },
          { name: 'Child Profile', image: `${contentUrl}/downloads/v5/child-profile.png`, asset: `${contentUrl}/downloads/v5/Harper-Patient-Profile.pdf` },
          { name: 'Adolescents Profile', image: `${contentUrl}/downloads/v5/adolescents-profile.png`, asset: `${contentUrl}/downloads/v5/Adolescent_Patient_Profiles.zip` },
          { name: 'Adults Profile', image: `${contentUrl}/downloads/v5/adult-profile.png`, asset: `${contentUrl}/downloads/v5/Adult_Patient_Profiles.zip` },
        ],
        footnote: "Real patients being treated with DUPIXENT. Individual results may vary.",
        jobCode: 'DUP.21.10.0257'
      }
    },
  ],
}
