import { contentUrl } from '../api'

export const VISIBLE_RESULTS = {
  name: 'VISIBLE_RESULTS',
  title: 'VISIBLE_RESULTS',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: "IMAGE",
      menuName: "ADULTS",
      subItems: [
        {
          subMenuName: "",
          src: `${contentUrl}/visible-results/AdultLegs.jpg`,
          contentTemplate: "MASKSLIDER",
          content: {
            before: { src: `${contentUrl}/visible-results/AdultLegsBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
            after: { src: `${contentUrl}/visible-results/AdultLegsAfter.png`, title: "WEEK 16:", text: "IGA 0 <br />(clear)" },
            title: "Adult patient 1 achieved a <br />4-point improvement in IGA",
            text: `<p>Real patient treated with DUPIXENT. Not a clinical trial patient. Adult patients 1 and 3 were on concomitant therapies, such as TCS, phototherapy, etc, at their prescribing physician’s discretion. Scoring was designated by the treating physician. Because this was a real-world patient, other factors may have influenced their treatment results. Individual results may vary.</p>
            <p>A clinical responder was defined as a patient achieving IGA 0 or 1 and at least a 2-point improvement from baseline.<sup>1</sup></p>
            <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
          },
        },
        {
          subMenuName: "",
          src: `${contentUrl}/visible-results/AdultHand.jpg`,
          contentTemplate: "MASKSLIDER",
          content: {
            before: { src: `${contentUrl}/visible-results/AdultHandBefore.png`, title: "BASELINE:", text: "IGA 3 <br />(moderate)" },
            after: { src: `${contentUrl}/visible-results/AdultHandAfter.png`, title: "WEEK 16:", text: "IGA 0 <br />(clear)" },
            title: "Adult patient 2 achieved a <br />3-point improvement in IGA",
            text: `<p>Real patient treated with DUPIXENT. Not a clinical trial patient. Adult patients 1 and 3 were on concomitant therapies, such as TCS, phototherapy, etc, at their prescribing physician’s discretion. Scoring was designated by the treating physician. Because this was a real-world patient, other factors may have influenced their treatment results. Individual results may vary.</p>
            <p>A clinical responder was defined as a patient achieving IGA 0 or 1 and at least a 2-point improvement from baseline.<sup>1</sup></p>
            <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
          },
        },
        {
          subMenuName: "",
          src: `${contentUrl}/visible-results/AdultTorso.jpg`,
          contentTemplate: "MASKSLIDER",
          content: {
            before: { src: `${contentUrl}/visible-results/AdultTorsoBefore.png`, title: "BASELINE:", text: "IGA 3 <br />(moderate)" },
            after: { src: `${contentUrl}/visible-results/AdultTorsoAfter.png`, title: "WEEK 16:", text: "IGA 0 <br />(clear)" },
            title: "Adult patient 3 achieved a <br />3-point improvement in IGA",
            text: `<p>Real patient treated with DUPIXENT. Not a clinical trial patient. Adult patients 1 and 3 were on concomitant therapies, such as TCS, phototherapy, etc, at their prescribing physician’s discretion. Scoring was designated by the treating physician. Because this was a real-world patient, other factors may have influenced their treatment results. Individual results may vary.</p>
            <p>A clinical responder was defined as a patient achieving IGA 0 or 1 and at least a 2-point improvement from baseline.<sup>1</sup></p>
            <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
          },
        },
      ],
    },
    // Visible Results - Adolescent
    {
      menuTemplate: "IMAGE",
      menuName: "ADOLESCENTS",
      subItems: [
        {
          subMenuName: "",
          src: `${contentUrl}/visible-results/Adolescent.jpg`,
          contentTemplate: "MASKSLIDER",
          content: {
            before: { src: `${contentUrl}/visible-results/AdolescentArmBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
            after: { src: `${contentUrl}/visible-results/AdolescentArmAfter.png`, title: "WEEK 16:", text: "IGA 2 <br />(mild)" },
            title: "Adolescent patient achieved a <br />2-point improvement in IGA",
            text: `<p>Real 12-year-old patient in the Phase 3 adolescent DUPIXENT trial (AD-1526). Patient had a baseline IGA of 4 and EASI of 31. Individual results may vary.</p>
            <p>A clinical responder was defined as a patient achieving IGA 0 or 1 and at least a 2-point improvement from baseline.<sup>1</sup></p>
            <div style="display: flex;">
              <p style="margin-right: 5px;">• </p>
              <p>This adolescent patient did not meet the primary endpoint in the clinical trial based on their IGA score at Week 16</p>
            </div>
            <p class='small'>EASI, Eczema Area and Severity Index; IGA, Investigator’s Global Assessment.</p>`,
          },
        },
      ],
    },
    // Visible Results - Children
    {
      menuTemplate: "IMAGE",
      menuName: "CHILDREN",
      subItems: [
        {
          subMenuName: "",
          src: `${contentUrl}/visible-results/ChildFace.jpg`,
          contentTemplate: "MASKSLIDER",
          content: {
            before: { src: `${contentUrl}/visible-results/ChildFaceBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
            after: { src: `${contentUrl}/visible-results/ChildFaceAfter.png`, title: "WEEK 16:", text: "IGA 1 <br />(almost clear)" },
            title: "Child patient 1 (6 years of age) achieved a 3-point improvement in IGA",
            text: `<p>Real patient in a Phase 3 pediatric DUPIXENT trial (AD-1652). Patient was prescribed concomitant TCS based on the clinical trial program. Individual results may vary.<sup>5</sup></p>
            <p>A clinical responder was defined as a patient achieving IGA 0 or 1.<sup>1</sup></p>
            <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
          },
        },
        {
          subMenuName: "",
          src: `${contentUrl}/visible-results/ChildWrist.jpg`,
          contentTemplate: "MASKSLIDER",
          content: {
            before: { src: `${contentUrl}/visible-results/ChildWristBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
            after: { src: `${contentUrl}/visible-results/ChildWristAfter.png`, title: "WEEK 16:", text: "IGA 1 <br />(almost clear)" },
            title: "Child patient 2 (10 years of age) achieved a 3-point improvement in IGA",
            text: `<p>Real patient in a Phase 3 pediatric DUPIXENT trial (AD-1652). Patient was prescribed concomitant TCS based on the clinical trial program. Individual results may vary.<sup>5</sup></p>
            <p>A clinical responder was defined as a patient achieving IGA 0 or 1.<sup>1</sup></p>
            <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
          },
        },
        {
          subMenuName: "",
          src: `${contentUrl}/visible-results/ChildFoot.jpg`,
          contentTemplate: "MASKSLIDER",
          content: {
            before: { src: `${contentUrl}/visible-results/ChildFootBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
            after: { src: `${contentUrl}/visible-results/ChildFootAfter.png`, title: "WEEK 16:", text: "IGA 1 <br />(almost clear)" },
            title: "Child patient 3 (9 years of age) achieved a 3-point improvement in IGA",
            text: `<p>Real patient in a Phase 3 pediatric DUPIXENT trial (AD-1652). Patient was prescribed concomitant TCS based on the clinical trial program. Individual results may vary.<sup>5</sup></p>
            <p>A clinical responder was defined as a patient achieving IGA 0 or 1.<sup>1</sup>
            <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
          },
        },
        {
          subMenuName: "",
          src: `${contentUrl}/visible-results/ChildKnees.jpg`,
          contentTemplate: "MASKSLIDER",
          content: {
            before: { src: `${contentUrl}/visible-results/ChildKneesBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
            after: { src: `${contentUrl}/visible-results/ChildKneesAfter.png`, title: "WEEK 16:", text: "IGA 3 <br />(moderate)" },
            title: "Child patient 4 (6 years of age) achieved a 1-point improvement in IGA",
            text: `<p>Real patient in a Phase 3 pediatric DUPIXENT trial (AD-1652). Patient was prescribed concomitant TCS based on the clinical trial program. Individual results may vary.</p>
            <p>A clinical responder was defined as a patient achieving IGA 0 or 1.<sup>1</sup></p>
            <div style="display: flex;">
              <p style="margin-right: 5px;">• </p>
              <p>Child Patient 4 did not meet the primary endpoint in the clinical trial based on their IGA score at Week 16</p>
            </div>
            <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
          },
        },
      ],
    },
    // Visible Results - Study
    {
      menuTemplate: "SINGLE",
      menuName: "STUDY DESIGNS",
      content: {
        contentTemplate: "IMAGE",
        content: {
          imageList: [{ src: `${contentUrl}/visible-results/v5/visible-results-study-design@2x.png`, alt: "Study Designs @ 2x" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/visible-results/v5/visible-results-study-design-mobile@2x.png`, alt: "Study Designs Mobile @ 2x" }],
        },
      },
    },
    // Visible Results - References
    {
      menuTemplate: "TEXT",
      menuName: "References",
      menuType: "REFERENCE",
      content: {
        contentTemplate: "IMAGE",
        content: {
          imageList: [{ src: `${contentUrl}/visible-results/v5/visible-results-references@2x.png`, alt: "Visible Results - @ References 2x" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/visible-results/v5/visible-results-mobile-references@2x.png`, alt: "Visible Results - @ References Mobile 2x" }],
        },
      },
    },
  ]
}
