const variables = {
    colors: {
        primary: "#049B77",
        primaryHover: "#0C8A62",
        primaryDark: "#0A5C42",
        secondary: "#FF9933",
        secondaryHover: "#C97928",
        secondaryDark: "#C97928",
        tertiary: "", 
        tertiaryHover: "", 
        tertiaryDark: "", 
        white: "#FFF",
        black: "#000",
        lightgrey: "#F5F5F5",
        grey: "#DBD9D9",
        darkgrey: "#796E65",
    },
}

const loadingScreen = {
    loadingScreenBorderBottom: '#FFCD02',
    loadingScreenFont: 'Antonio',
    loadingScreenLineHeight: '1.1'
}

const header = {
    headerBg: variables.colors.white,
    headerPrimary: variables.colors.darkgrey,
    headerSecondary: variables.colors.lightgrey,
    headerTertiary: variables.colors.grey,
}

const menu = {
    menuTitleBg: variables.colors.white,
    menuBgPrimary: variables.colors.primary,
    menuBgPrimaryActive: variables.colors.white,
    menuBgPrimaryHover: variables.colors.white,
    menuBgSecondary: variables.colors.lightgrey,
    menuBgSecondaryActive: variables.colors.white,
    menuBgSecondaryHover: variables.colors.grey,
    menuBgTertiary: variables.colors.white,
    menuBgTertiaryActive: "",
    menuBgTertiaryHover: "",
    
    menuTabActive: variables.colors.secondary,
    menuTabInactive: variables.colors.primary,

    menuTextPrimary: variables.colors.primary,
    menuTextSecondary: variables.colors.darkgrey,
    menuTextTertiary: variables.colors.white,

    menuHeaderFontSize: "16px",
    menuChildFontSize: "12px",

    menuHeaderSpacer: "4px",
}

const navigation = {
    navBgPrimary: variables.colors.primary,
    navBgPrimaryActive: variables.colors.primary,
    navBgPrimaryHover: variables.colors.primaryHover,
    navBgPrimaryDark: variables.colors.primaryDark,
    navBgSecondary: "#CCC",
    navBgSecondaryActive: variables.colors.white,
    navBgSecondaryHover: variables.colors.lightgrey,
    navBgTertiary: variables.colors.secondary,
    navBgTertiaryActive: variables.colors.secondaryHover,
    navBgTertiaryHover:  variables.colors.secondary,

    navTabActive: variables.colors.secondary,
    navTabInactive: variables.colors.primary,

    navTextPrimary: variables.colors.primary,
    navTextSecondary: variables.colors.white,
    navTextTertiary: variables.colors.darkgrey,
}

const buttons = {
    buttonBgPrimary: variables.colors.primary,
    buttonBgSecondary: variables.colors.white,

    buttonBorderPrimary: variables.colors.primary,
    buttonBorderSecondary: variables.colors.white,

    buttonTextPrimary: variables.colors.primary,
    buttonTextSecondary: variables.colors.white,
}

const alert = {
    alertBgPrimary: variables.colors.primary,
    alertBgSecondary: variables.colors.secondary,
    alertBgTertiary: variables.colors.grey,
    alertBgTertiaryHover: variables.colors.white,

    alertBorderPrimary: variables.colors.primary,
    alertBorderSecondary: variables.colors.secondary,

    alertTextPrimary: variables.colors.primary,
    alertTextSecondary: variables.colors.secondary,
}

const stripNav = {
    stripNavBgPrimary: variables.colors.primary,
    stripNavBgSecondary: variables.colors.darkgrey,
    stripNavBgActive: variables.colors.white,
    stripNavBgBorder: variables.colors.secondary,
    
    stripNavMobileBgPrimary: variables.colors.lightgrey,
    stripNavMobileBgActive: variables.colors.primary,
    stripNavMobileBgBorder: variables.colors.primary,

    stripNavMobileBgLinks: variables.colors.grey,
    stripNavMobileLinksText: variables.colors.darkgrey,

    stripNavHeight: "0px",
    stripNavHeightMobile: "90px",
}

const scrollbar = {
    scrollbarBgGradient: "#75c34e, #009b77, #187c2e",
    scrollbarThumb: "#97d700",
}

const footer = {
    footerPrimary: "#11B07E",
    footerSecondary: variables.colors.darkgrey,
}

const downloads = {
    downloadsBgPrimary: "#000",
    downloadsBgSecondary: "#0C7D63",
    downloadsTitleBgPrimary: "#96CA4F",
    downloadsTitleBgSecondary: "#00A880",
}

const theme = {
    loadingScreen,
    header,
    menu,
    navigation,
    buttons,
    alert,
    stripNav,
    scrollbar,
    footer,
    downloads
  }
 
export default theme