import { contentUrl } from '../api'

export const STUDY_DESIGNS = {
  name: 'STUDY_DESIGNS',
  title: 'STUDY_DESIGNS',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: "",
      menuName: "",
      content: {
        contentTemplate: "IMAGE",
        content: {
          imageList: [{ src: `${contentUrl}/study-designs/v5/study_designs@2x.png`, alt: "Study Designs @ 2x" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/study-designs/v5/study-design-mobile@2x.png`, alt: "Study Designs Mobile @ 2x" }],
        },
      },
    },
  ],
}
