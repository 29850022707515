import { contentUrl } from '../api'

export const CLINICAL_DATA = {
  name: 'CLINICAL_DATA',
  title: 'CLINICAL_DATA',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: 'DROPDOWN',
      menuName: 'CLINICAL PROGRAM',
      funFact: {
        funFactTitle: "Did you know?",
        funFactBody: "DUPIXENT has been studied across 3 conditions driven in part by type 2 inflammation<sup>1</sup>",
      },
      subItems: [
        {
          subMenuName: 'TRIAL PROGRAM',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/clinical-data/v5/trial-program@2x.png`, alt: "Clinical Data - Trial Program @ 2x" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/clinical-data/v5/trial-program-mobile@2x.png`, alt: "Clinical Data - Trial Program Mobile @ 2x" }],
          },
        },
        {
          subMenuName: 'ATOPIC DERMATITIS TRIALS',
          contentTemplate: "IMAGECONTENT",
          content: {
            imageList: [{ src: `${contentUrl}/clinical-data/v5/ad-trials@2x.png`, alt: "Clinical Data - AD Trials @ 2x" }],
            mobileImageList: [
              { srcMobile: `${contentUrl}/clinical-data/v5/ad-trials-mobile1@2x.png`, alt: "Clinical Data - AD Trials Mobile @ 2x" },
              { srcMobile: `${contentUrl}/clinical-data/v5/ad-trials-mobile2@2x.png`, alt: "Clinical Data - AD Trials Mobile 2 @ 2x" },
            ],
          },
        },
      ],
    },
    {
      menuTemplate: 'DROPDOWN',
      menuName: 'EFFICACY',
      funFact: {
        funFactTitle: "Did you know?",
        funFactBody: "Adult patients saw itch relief after the first dose of DUPIXENT at Week 2",
        funFactFootnote: "(≈18% with DUPIXENT + TCS [n=102] vs 8% with placebo + TCS [n=299]; <i>P</i>=0.0062)<sup>2,4</sup>",
      },
      subItems: [
        {
          subMenuName: 'ITCH REDUCTION',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/clinical-data/v5/itch-reduction@2x.png`, alt: "Clinical Data - Itch Reduction @ 2x" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/clinical-data/v5/itch-reduction-mobile@2x.png`, alt: "Clinical Data - Itch Reduction Mobile @ 2x" }],
          },
        },
        {
          subMenuName: 'SKIN CLEARANCE',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/clinical-data/v5/skin-clearance@2x.png`, alt: "Clinical Data - Skin Clearance @ 2x " }],
            mobileImageList: [{ srcMobile: `${contentUrl}/clinical-data/v5/skin-clearance-mobile@2x.png`, alt: "Clinical Data - Skin Clearance Mobile @ 2x" }],
          },
        },
        {
          subMenuName: 'EASI ACROSS BODY REGIONS',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/clinical-data/v5/easi-results@2x.png`, alt: "Clinical Data - EASI Results @ 2x" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/clinical-data/v5/easi-results-mobile@2x.png`, alt: "Clinical Data - EASI Results Mobile @ 2x" }],
          },
        },
        {
          subMenuName: 'FLARE DATA',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/clinical-data/v5/flare-data@2x.png`, alt: "Clinical Data - Flare Data @ 2x " }],
            mobileImageList: [{ srcMobile: `${contentUrl}/clinical-data/v5/flare-data-mobile@2x.png`, alt: "Clinical Data - Flare Data Mobile @ 2x" }],
          },
        },
      ],
    },
    {
      menuTemplate: 'DROPDOWN',
      menuName: 'SAFETY PROFILE',
      funFact: {
        funFactTitle: "Did you know?",
        funFactBody: "The 52-week safety profile of DUPIXENT + TCS in adults was generally consistent with the Week 16 adult safety profile<sup>1</sup>",
      },
      subItems: [
        {
          subMenuName: 'LONG-TERM SAFETY PROFILE',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/clinical-data/v5/adverse-events@2x.png`, alt: "Clinical Data - Adverse Events @ 2x" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/clinical-data/v5/adverse-events-mobile@2x.png`, alt: "Clinical Data - Adverse Events Mobile @ 2x" }],
          },
        },
        {
          subMenuName: 'IMPORTANT CONSIDERATIONS',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/clinical-data/v5/important-considerations@2x.png`, alt: "Clinical Data - Important Considerations @ 2x" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/clinical-data/v5/important-considerations-mobile@2x.png`, alt: "Clinical Data - Important Considerations Mobile @ 2x" }],
          },
        },
      ],
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'OTHER ATTRIBUTES',
      funFact: {
        funFactTitle: "Did you know?",
        funFactBody: "DUPIXENT is not an immunosuppressant and requires no initial lab testing or ongoing lab monitoring, according to the Prescribing Information<sup>1</sup>",
      },
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/clinical-data/v5/other-attributes@2x.png`, alt: 'Clinical Data - Other Attributes @ 2x '}],
          mobileImageList: [{srcMobile: `${contentUrl}/clinical-data/v5/other-attributes-mobile@2x.png`, alt: 'Clinical Data - Other Attributes Mobile @ 2x'}]
        }
      }
    },    
    {
      menuTemplate: 'DROPDOWN',
      menuName: 'REAL-WORLD DATA',
      funFact: {
        funFactTitle: "Did you know?",
        funFactBody: "Improvement in disease severity was assessed in a real-world study.",
      },
      subItems: [
        {
          subMenuName: 'ITCH REDUCTION/<br class="line-break" />SKIN CLEARANCE',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/clinical-data/v5/real-world-data@2x.png`, alt: "Clinical Data -  Real World Data @ 2x" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/clinical-data/v5/real-world-data-mobile@2x.png`, alt: "Clinical Data -  Real World Data Mobile @ 2x" }],
          },
        },
        {
          subMenuName: 'SATISFACTION/<br class="line-break" />PERSISTENCE',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/clinical-data/v5/persistence-data@2x.png`, alt: "Clinical Data - Persistence Data @ 2x" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/clinical-data/v5/persistence-data-mobile@2x.png`, alt: "Clinical Data - Persistence Data Mobile @ 2x" }],
          },
        },
      ],
    },
    {
      menuTemplate: "FUNFACTFOOTER",
      menuName: "Did you know?",
      menuBody: "DUPIXENT has been studied across 3 diseases that are driven in part by type 2 inflammation<sup>1</sup>",
      hideInMobileSubNav: true,
    },
    {
      menuTemplate: "TEXT",
      menuName: "References",
      menuType: "REFERENCE",
      content: {
        contentTemplate: "IMAGE",
        content: {
          imageList: [{ src: `${contentUrl}/clinical-data/v5/references@2x.png`, alt: "Clinical Data - References @ 2x" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/clinical-data/v5/references-mobile@2x.png`, alt: "Clinical Data - References Mobile @ 2x" }],
        },
      },
      funFact: {
        hideFunFact: true
      },
    },
  ],
}
