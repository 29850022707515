export const hasSeenFlyThrough = () => {
  try {
    const flyThroughSeen = localStorage.getItem('FlyThroughSeen')
    if (flyThroughSeen === 'true') {
      return true;
    } else {
      return true; // set as true to remove flyThrough (false is correct functionality)
    }
  } catch (error) {
    return true
  }
}

export const setHasSeenFlyThrough = () => {
  try {
    localStorage.setItem('FlyThroughSeen', 'true')
  } catch (error) {}
}

export const setSlideshowSeen = () => {
  try {
    localStorage.setItem('SlideshowSeen', 'true')
  } catch (error) {}
}
