export * from './MOA'
export * from './CLINICAL_DATA';
export * from './DOWNLOADS';
export * from './GETTING_STARTED';
export * from './REAL_STORIES';
export * from './VISIBLE_RESULTS';
export * from './STUDY_DESIGNS';

export enum ContentTypes {
  MOA = 'MOA',
  CLINICAL_DATA = 'CLINICAL_DATA',
  DOWNLOADS = 'DOWNLOADS',
  GETTING_STARTED = 'GETTING_STARTED',
  REAL_STORIES = 'REAL_STORIES',
  VISIBLE_RESULTS = 'VISIBLE_RESULTS',
  STUDY_DESINGS = 'STUDY_DESIGNS',
}

export const contentNavigation = [
  { title: 'MOA', instance: ContentTypes.MOA },
  { title: 'CLINICAL_DATA', instance: ContentTypes.CLINICAL_DATA },
  { title: 'DOWNLOADS', instance: ContentTypes.DOWNLOADS },
  { title: 'GETTING_STARTED', instance: ContentTypes.GETTING_STARTED },
  { title: 'REAL_STORIES', instance: ContentTypes.REAL_STORIES },
  { title: 'VISIBLE_RESULTS', instance: ContentTypes.VISIBLE_RESULTS },
  { title: 'STUDY_DESIGNS', instace: ContentTypes.STUDY_DESINGS },
]
