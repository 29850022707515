import { styled } from '@lxlabs/vc-components'

export const SlideshowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #D7D7D7;
`

export const SlideshowImage = styled.img`
    width: 100%;
`

export const SlideshowButton = styled.div<{ color: any }>`
    width: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 50%;
    background: rgba(${props => props.color}, 0.5);
    cursor: pointer;
`

export const MobileArrowsImage = styled.div`
    color: white;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999999;

    p {
        position: absolute;
        top: 50%;
        text-align: center;
        width: 80%;
        left: 10%;
        font-size: 20px;
        font-weight: bold;
    }
`

export const MobileArrow = styled.span<{ left?: any, right?: any }>`
    border: none; 
    background: none; 
    position: absolute; 
    top: calc(51% - 2px); 
    left: ${(props) => (props.left)};
    right: ${(props) => (props.right)};
    display: inline; 
`